import React, { useEffect, useState } from 'react'

const anychart = window.anychart;


anychart.licenseKey("datagro-23690706-e6c66f65");
// anychart.format.outputLocale(getLang());
anychart.format.outputDateTimeFormat("dd-MM-yyyy HH:mm:ss");
anychart.ui.rangePicker();


const CREDITS = {
    "text": "DATAGRO",
    "url": "https://www.datagro.com",
    "alt": "DATAGRO - Consultoria agrícola independente, que produz análises e dados primários sobre as principais commodities agrícolas",
    "imgAlt": "Datagro - Consultoria Agrícola",
    "logoSrc": "https://portal.datagro.com/favicon.ico",
    "enabled": true,
}





    export default function Candle({ data, title='', container='container', yGrid = true, xGrid= true , height= 400, background}) {
    const [chart, setChart] = useState(null)



    useEffect(() => {
        let arr = []
 
        for (var i = 0; i < data.length; i++) {
            arr.push([data[i].dia, data[i].abe, data[i].maxi, data[i].mini, data[i].ult])
        }

        var chrt = anychart.stock();
        var plot = chrt.plot(0);

        plot.yGrid(true)
            .xGrid(true)
            .yMinorGrid(false)
            .xMinorGrid(false);


            chrt.background({fill: background});
           








        chrt.scroller().line(arr);
        chrt.scroller().fill('green 0.1');
        chrt.scroller().selectedFill('green 0.5');
        chrt.scroller().palette(['#006400'])

        var extraYAxis = plot.yAxis(1);
        extraYAxis.orientation("right");
        var series = plot.candlestick(arr);
        if (data[0])
            series.name('CBIO:B3   ');

      

        series.risingFill('green', 1)
            .fallingFill('red', 1)
            .risingStroke('green', 1)
            .fallingStroke('red', 1);

 


            var volumePlot = chrt.plot(1);
            // set yAxis labels formatter
            volumePlot.yAxis().labels().format('{%Value}{scale:(1000)(1)|(k)}');
            // set crosshair y-label formatter
            volumePlot
              .crosshair()
              .yLabel()
              .format('{%Value}{scale:(1000)(1)|(k)}');
      
            // create volume series on the plot
            var volumeSeries = volumePlot.column(arr);
            // set series settings
            volumeSeries.name('Volume');
            volumeSeries.color( "#5b6f2c")
        
            volumePlot.height('25%');
       // chrt.contextMenu(false);
       chrt.credits(CREDITS);
        chrt.draw();
        chrt.padding().right(50);

        chrt.container(`123aasa`);
      

    }, [])


    return     (<div>

<div id="123aasa" style={{ height : height, marginBottom: 10 }}  />
</div>)
}







