import React, {useState, useEffect, memo} from 'react'


import Chart from './Chart/ChartAnyChart'

function Candle({data}) {

    const [chartBar, setChartBar] = useState()
    const [dadoo, setdadoo] = useState(data)

    console.log('log Candle:',data);

    useEffect(() => {
      
        let arr = []
       console.log(data.length)
        for (var i = 0; i < data.length; i++) {
            arr.push([data[i].dia, data[i].abe, data[i].maxi, data[i].mini, data[i].ult])
        }

        console.log('tesssssss', arr);
        



    let data1 = [{
        data: arr,
        name: 'Preço Médio',
      },{
        data: arr,
        name: 'Preço Médio',
      }]
  
  
      let jsonChart0 = {
        "chart": {
          "type": "candlestick",
          "series": data1,
  
          "stroke": "5 red",
  
          "legend": false,
          "title": {
            'enabled': false
          },
          // y axes settings
          yAxes: [{     // chart.yAxis().title().text("Basic Y Axis");
          }, {                                // chart.yAxis(1)
            orientation: "right",              //   .orientation("right")
            //   .scale(customYScale)
          }],
  
          "container": "container10"
  
        }
      }

      setChartBar(jsonChart0)



    }, [])
    

    return (
        <div>
                  {chartBar && <div style={{ marginTop: 30, marginBottom:100 }} >  <p className="tilteChart" >CBIO</p> <Chart data={dadoo} height={600} background={'#eaeaea'} container={'char1'} xGrid={false} /></div>}
        </div>
    )
}





const AutoLoadExtraNews = props => {


    const [data, setData] = useState()

        var apiQuery = {
            searchQuery: 'cbio',
            quantity:10
        }
    
        useEffect(() => {
            async function getNews(){
                let response = await fetch(`https://api.spo.datagro.com/precos/dados/?i=2021-02-17&f=2021-05-17&formato=json&a=CBIo&periodo=d&idioma=pt-br`);
                console.log(response.body);
                
               let resJson = await response.json();

               setData(resJson);
               
            }

            getNews()

            
        }, [])
      

    if(data) return <Candle data={data} />
    return <div/>
}

export default memo(AutoLoadExtraNews)
export { Candle, AutoLoadExtraNews }