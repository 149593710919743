







export function formatFloat(x, n, lang ) {
    if(x==undefined) return 
    if(n==undefined) return 
    n= parseInt(n)
   
    let newNb = ''
    let ultDecimais = ''
    let nbTemp
    try {
        if(typeof x === 'float'){
            console.log('float??', x);
            nbTemp=x.toString()
        }else{
      
            x=x.toString()
            nbTemp = x.replaceAll(/[.\s]/g, '')
        }


        

        
     

     

        const nb = (typeof nbTemp === 'string' ? nbTemp : nbTemp.toString()).split('.');


        if(nb.length == 1){
            while (ultDecimais.length < n) ultDecimais += '0';
            newNb = nb[0]+'.'+ ultDecimais
        }else{
           let ult = [...nb].pop()
            while (ult.length < n) ult += '0';
            ult = ult.substring(0,n)
            for (let index = 0; index < (nb.length -1); index++) {
                newNb += nb[index] 
            }
            newNb += '.'+ult       
        }

    

        return Number.parseFloat(x).toLocaleString(lang, {
            minimumFractionDigits: n,  
            maximumFractionDigits: n
         })

     








        // x = x.replace(/,/g, '.')

        // const v = (typeof x === 'string' ? x : x.toString()).split('.');

        

        // if (n <= 0) {
        //     return parseFloat(v[0]).toLocaleString(getLang())
        // }

        // let fs = v[2] || '';
        // let f = v[1] || '';
        // if (v.length > 2) {
        //     let vd = `${v[0]}${v[1]}.${fs.substring(0,n)}`;

        //     val =  parseFloat(vd).toLocaleString(getLang());

        //     //return vd.toLocaleString(getLang());
        //     return val
        // }
        // if (v.length < 2) {
        //     let vd=  `${v[0]}.${[...v].pop().substring(0,n)}`

        //     return parseFloat(vd).toLocaleString(getLang())
        // };
        
        
        //      while (f.length < n) f += '0';

        //     let val= `${v[0]}.${v[1].substring(0,n)}`
   
        //     val =  parseFloat(val).toLocaleString(getLang())

        //     return val





            // const og = Math.pow(10, decimais)
            // let as=  Math.floor(val * og) / og;
            // console.log(as);
            // return as.toLocaleString(getLang())
          

        // if (typeof val === 'number') {º
        //     console.log('number', val, decimais);
        //     return val.toLocaleString(getLang())
        // } else {
        //     if (val) {
        //         console.log(val);
        //         val = val.replace(/,/g, '.')
        //         console.log(val);
             
        //         console.log('number>>', val);
        //         console.log(parseFloat(val).toFixed(decimais))
        //         val = new Intl.NumberFormat(getLang()).format(val)
        //         console.log(val);
        //         return (new Intl.NumberFormat(getLang()).format(val))

        //     }
            
       // }
    } catch (error) {
        console.log(error);
        return x
    }

}


export function formatFloatLocale(val) {

    if (typeof val === 'number') {
        return val.toLocaleString()
    } else {
        try {

            return new Intl.NumberFormat().format(val)

        } catch (error) {
            return val
        }
    }
}


export function formatDate(val, lang) {
 
    try {
        let data = parseDMY(val)
        let dat = new Intl.DateTimeFormat(lang).format(data)

        return dat
    } catch (error) {

        return val
    }

}



function parseDMY(value) {

    var date = value.split("-");
    if (date.length < 3)
        return false

    var y = parseInt(date[0], 10),
        m = parseInt(date[1], 10),
        d = parseInt(date[2], 10);


    return new Date(y, m - 1, d)
}


export  function formatY(value) {

    var date = value.split("-");
    if (date.length < 3)
        return false

    var y = parseInt(date[0], 10),
        m = parseInt(date[1], 10),
        d = parseInt(date[2], 10);


    return y
}


export function getLocaleDateString(lang){

    var formats = {   
       "en-us" : "MM/DD/yyyy",
       "pt-br" : "DD/MM/yyyy",
      
    };
    
       return formats[lang] || 'DD/MM/yyyy';
    
    } 



    // export function validaTeclado(component, evt, mascara) {
    //     var charCode = (evt.which) ? evt.which : evt.keyCode;
    //     //se for backspace sempre permite a ação do botão
    //     if(charCode == 8) {
    //         return true;
    //     }
    //     //Verifica se o valor do caractere nao corresponde a um numero
    //     //Caso nao corresponda retorna false
    //     if (charCode > 31 && (charCode < 48 || charCode > 57))
    //        return false;
    //     //verifica o tamanho do campo com a mascara
    //     //primeiro remove os caracteres especiais da mascara (fica apenas
    //     //o 9 e o #
    //     var maskClear = mascara.replace(/[^#9]+/gi, '');
    //     var txt = component.value.replace(/[^\d]+/gi, '');
    //     if(txt.length >= maskClear.length) {
    //     return false;
    //     }
    //     //caso não haja problema, aceita
    //     return true;
    //  }

    //  export function maskIt(component, event, mascara) {
    //     // Cancela se o evento for Backspace
    //     if (!e)
    //         var e = window.event;
    //     if (e.keyCode)
    //         code = e.keyCode;
    //     else if (e.which)
    //         code = e.which;
     
    //     // Variaveis da função
    //         var txt = component.value.replace(/[^\d]+/gi, '').reverse();
    //     var mask = mascara.reverse();
    //     var ret = "";
    //     txt = removeLastZeros(txt);
    //     // Loop na mascara para aplicar os caracteres
    //     for ( var x = 0, y = 0, z = mask.length; x < z && y < txt.length;) {
    //         if (mask.charAt(x) != '#' && mask.charAt(x) != '9') {
    //             ret += mask.charAt(x);
    //             x++;
    //         } else {
    //             ret += txt.charAt(y);
    //             y++;
    //             x++;
    //         }
    //     }
    //     component.value = ret;
    //     addZero(component);
    //     setFieldPosition(component);
    // }

    export function addZero(component) {
        var value = component.value;
        if(value.length > 2) {
            return;
        }
        switch (value.length) {
        case 0:
            component.value = '0.00';
            break;
        case 1:
            component.value = '0.0' + value;
            break;
        case 2:
            component.value = '0.' + value;
            break;
        }
    }

export const decimalSeparator = (lang) =>{
    var formats = {   
        "en-us" : ".",
        "pt-br" : ",",
       
     };
     
        return formats[lang] || ',';

}
export const thousandSeparator = (lang) =>{
    var formats = {   
        "en-us" : ",",
        "pt-br" : ".",
       
     };
     
        return formats[lang] || '.';

}



export function Encrypt(value) 
{
if(!value) return value
  var result="";
  for(let i=0;i<value.length;i++)
  {
    if(i<value.length-1)
    {
        result+=value.charCodeAt(i)+10;
        result+="Da4dd2dd123DT";
    }
    else
    {
        result+=value.charCodeAt(i)+10;
    }
  }
  return result;
}
export function Decrypt(value)
{
  var result="";
  var array = value.split("Da4dd2dd123DT");

  for(let i=0;i<array.length;i++)
  {
    result+=String.fromCharCode(array[i]-10);
  }
  return result;
} 