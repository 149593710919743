import React, { useState, useEffect, memo } from 'react'
import styles from './Header.css'
import { formatDate, formatFloat, formatFloatLocale } from '../vdl'

import './Header.css'




function Header(props) {


    console.log(props);

    const [quote, setQuote] = useState({})
    const [ws, setWs] = useState({})

    let cod = props.ativo.cod

    useEffect(() => {

        setQuote(props.ativo)


    }, [])



    function color(i) {
        if (i == null) return styles.fontGrey
        if (i < 0) return styles.fontRed
        if (i > 0) return styles.fontGreen
        return styles.fontGrey
    }



    function seta(i) {
        if (i == null) return <div ></div>
        if (i < 0) return <div className={`seta fontRed`}>▼</div>
        if (i > 0) return <div className={`seta fontGreen`}>▲</div>
        return <div></div>
    }



    let space = props.rvEspace ? "resetBox" : "box"
    let spaceQuote = props.rvEspace ? "resetQuote" : "quote"

    const t = (text) => text


    return (
        <div className="quoteHeader">

            <div className="longo" >
                {quote.longo}
            </div>

            <div className="dd" >

                <div className="spaceQuote" >

                    <div className="ultB">
                        <div className="codeCbio" >CBIO:B3</div>
                    </div>

                </div>




                <div className="spaceQuote" >

                    <div className="ult">
                        <div className="seta" >{seta(quote.var)}  {formatFloat(quote.ult, quote.decimais)}</div>
                    </div>
                  
                </div>
              
                    <div className="dados">

                        <div className={`${space}`} >
                            <div className={`${"var"} ${color(quote.var)}`}>{formatFloat(quote.var, 2) || 0}%</div>
                            <div className="txt" >{props.t('var')} ({props.t('day')})</div>
                        </div>

                        <div className={`${space}`} >
                            <div className="mini">{formatFloat(quote.mini, quote.decimais)}</div>
                            <div className="txt" >{props.t('lowFull')} ({props.t('day')})</div>
                        </div>


                        <div className={`${space}`} >
                            <div className="mini">{formatFloat(quote.maxi, quote.decimais)}</div>
                            <div className="txt" >{props.t('highFull')} ({props.t('day')})</div>
                        </div>

                        <div className={`${space}`} >
                            <div className="mini">{formatFloat(quote.abe, quote.decimais)}</div>
                            <div className="txt" >{props.t('open')} ({props.t('day')})</div>
                        </div>


                    </div>

              
            </div>
            <div className="at" > Ultima Atualização: {formatDate(quote.dia)}  </div>
        </div>
    )
}



const AutoLoadExtraNews = props => {


    const [data, setData] = useState()

    var apiQuery = {
        searchQuery: 'cbio',
        quantity: 10
    }

    useEffect(() => {
        async function getNews() {
            let response = await fetch(props.api);
            console.log(response.body);

            let resJson = await response.json();

            setData(resJson);

        }

        getNews()


    }, [])


    if (data) return <Header ativo={data[0]} t={props.t} />
    return <div />
}

export default memo(AutoLoadExtraNews)
export { Header, AutoLoadExtraNews }
