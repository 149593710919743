
import React, {useEffect, useState, memo} from 'react'

// import ContentLoader from '../Loaders/ContentLoader'

// import PreviewModal from '../Analysis/PreviewModal'
// import enums from '../../enums'
// import url from '../../util/url'

// //import ExtraNewsItem from './ExtraNewsItem'

 import './NewsCbioSidebar.css'

// import { SearchLink } from '../Search/SearchItem'

// import t, { formatDate } from 'ui-stack/lang'

import { formatDate, formatFloat, formatFloatLocale } from './vdl'

const ExtraNews = ({ data, error, isColumn }) => {

    console.log('returnoonnk', data, error, isColumn);




    if (!data)
        data = []


    return (
        
        <div style={{ height: 800, overflow: 'auto', marginBottom: 15 }}>
        {data.map(news =>
        <View key={news.id} data={news}  />

   )}
   </div>)
}

//   <View key={news.id} data={news}  />


const View =  ({ data }) => {

    
    

        if (!data)
            return null
    
        var type =  data.type
    
    
        if (type === 1)
            return  <News data={data}  />
    
        // if (type === 3)
        //     return  <News data={data} linkTo={url.news(data)} />
    
         if (type === 2)
             return <Analysis data={data}/>
    
        return null
}




const Analysis =  ({ data }) => {



  const publishDate = new Date(data.publishDate)
    var linkTo = `https://portal.datagro.com/view/analysis/?analysisId=${data.id}`

  
        return (
         <a href={linkTo} target="_blank">

          
                <div className="title">
                {data.title}
               
                </div>
                <div  className="data">
                {publishDate.toLocaleDateString()} - {publishDate.toLocaleTimeString()} 
                </div>
              
                
           

            
        </a>
        )
}



const News=  ({ data }) => {

    if (!data)
    return null



var date = new Date(data.publishDate)
const publishDate = new Date(data.publishDate)
// var marketData = getMarket(data)




    var linkTo = `https://portal.datagro.com/${data.id}`

return (

        <a href={linkTo} target="_blank">

           

<div className="title">
                {data.title}
                </div>
                <div  className="data">
                {publishDate.toLocaleDateString()} - {publishDate.toLocaleTimeString()} 
                </div>
              

     
        </a>

)
}








const AutoLoadExtraNews = props => {


    const [data, setData] = useState()

        var apiQuery = {
            searchQuery: 'cbio',
            quantity:10
        }
    
        useEffect(() => {
            async function getNews(){
                let response = await fetch(`https://api.datagro.com/api/search/list?searchQuery=cbio&quantity=9`);
                console.log(response.body);
                
               let resJson = await response.json();

               setData(resJson);
               
            }

            getNews()

            
        }, [])
      

    if(data) return <ExtraNews data={data} />
    return <div/>
}

export default memo(AutoLoadExtraNews)
export { ExtraNews, AutoLoadExtraNews }
