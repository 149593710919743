import React, { useEffect, Fragment } from 'react'


const anychart = window.anychart;

anychart.licenseKey("datagro-23690706-e6c66f65");
// anychart.format.outputLocale(getLang());
anychart.format.outputDateTimeFormat("dd-MM-yyyy HH:mm:ss");
anychart.ui.rangePicker();

const TYPE = ['line', 'column-3d', 'column-3d']

const CREDITS = {
    "text": "DATAGRO",
    "url": "https://www.datagro.com",
    "alt": "DATAGRO - Consultoria agrícola independente, que produz análises e dados primários sobre as principais commodities agrícolas",
    "imgAlt": "Datagro - Consultoria Agrícola",
    "logoSrc": "https://portal.datagro.com/favicon.ico",
    "enabled": true,
}


export default function Json({ data, title='', container='container', yGrid = true, xGrid= true , height= 400, background}) {


console.log({ data, title, container, yGrid , xGrid , height, background})



    useEffect(() => {

       
        if (data.chart) {
            console.log('chart',data);
            
            anychart.onDocumentReady(function () {
                // JSON data
                // anychart.format.outputLocale(getLang());

                let chart = anychart.fromJson(data);
               if(background){
                 chart.background({fill: background});
               }
                    
               
                    
                chart.credits(CREDITS)
                chart.yGrid(yGrid).xGrid(xGrid);
                
                chart.xAxis().labels().rotation(90);


                chart.yMinorGrid().enabled(true);
                // draw chart
                chart.contextMenu(false);
                if(container!='container')
                    chart.container(`${container}`);
                chart.draw();


                // set JSON data

            });
        }
        return () => {

        }

    }, [])


return(
    <div>
        {title && <h1>{title}</h1> }
<div id={`${container}`} style={{ height : height, marginBottom: 10 }} />
    </div>
)



}
