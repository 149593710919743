import React, { useState, useEffect } from 'react';
// import './App.css';
import Chart from './Chart/ChartAnyChart'
// import Chart from './Chart'

import ChartJson from './Json.AnyChart'
import { formatDate, formatFloat, formatFloatLocale, formatY  } from './vdl'

import ProgressBar from 'react-customizable-progressbar'
import styles from './cbioSantander.css'

import './cbioSantander.css';

import Clear from './Clear'

import Header from './Header/Header'


import Candle from './Candle'
import { getByTestId } from '@testing-library/dom';


import TopBar from './Header/TopBar'

import disclaimer from './disclaimer.json'
import {ExtraNews} from './NewsCbioSidebar'



const AutoLoadQuote = ({ location, match }) => {



  const [dados, setDados] = useState()
  const [loading, setLoading] = useState(true)
  const [config, setConfig] = useState()

  //VAlidacao de usuario


  useEffect(() => {


    async function getConfig(){
      console.log('entrou')
      fetch('./config.json').then(response => {
        console.log(response);
        return response.json();
      }).then(data => {
        // Work with JSON data here
        console.log('dataaaaaaaa:', data)
        setConfig(data);
        fetch(data.api).then(response => {
          console.log(response);
          return response.json();
        }).then(data => {
          setDados(data)
          setLoading(false)
      }).catch(err => {
        // Do something for an error here
        console.log("Error Reading data " + err);
      });






      }).catch(err => {
        // Do something for an error here
        console.log("Error Reading data " + err);
      });
    }

    getConfig()

    async function getCbio() {
      let response = await fetch(`https://api.spo.datagro.com/precos/cbio/pagina_dados/`);
      console.log(response.body);

      let resJson = await response.json();
      setDados(resJson)
      setLoading(false)
    }


    window.interval23 = setInterval(
      () =>  getConfig(),
      60000
    )


    return () => {
      clearInterval(window.interval23)
    }


  }, [])



  if (!loading)
    return <Santander data={dados} config={config} />



  return (
    <h1 ></h1>
  )
}

function parseData(data) {


  console.log(data);


  // if (typeof data === 'boolean') {
  //     return {
  //         hasAccess: data
  //     }
  // }
  return data
}














function Lang(lang) {

  console.log(lang);

  if (lang == 'pt' || lang == 'pt-br') {
    return {
      lang: 'pt-br',
      title: 'CBIO - CRÉDITO DE DESCARBONIZAÇÃO',
      qtt: 'Qtd',
      price: 'Preço Médio',
      date: 'Data',
      vol: 'Volume',
      neg: 'Neg',
      maxi: 'Max',
      mini: 'Min',


      medio: 'Médio',
      titleMedio: 'Trade Recap - Preço Médio',
      titleVolume: 'Trade Recap - Volume',
      titleOferta: 'Ofertas de Venda',
      titleTrade: 'Trade Recap',


    }
  } else if (lang == 'en' || lang == 'en-us') {
    return {
      lang: 'en',
      title: 'CBIO - CRÉDITO DE DESCARBONIZAÇÃO en',
      qtt: 'Qtty',
      price: 'Ask',
      date: 'Date',
      vol: 'Volume',
      neg: 'Trades',
      medio: 'Average',
      titleMedio: 'Trade Recap - Average Price',
      titleVolume: 'Trade Recap - Volume',
      titleOferta: 'Order Book',
      titleTrade: 'Trade Recap'
    }
  }


}



function Santander({ data, config }) {

  console.log(data);



  const [dados, setDados] = useState(data)

  const [dadosR, setDadosR] = useState(data)

  const [chartBar, setChartBar] = useState({})
  const [chartLine, setChartLine] = useState({})
  const [Loading, setloading] = useState(true)
  const [progress, setProgress] = useState(8)

const [reverts, setRevert] = useState()


const [cumprimento, setCumprimento] = useState(0)
const [meta, setMeta] = useState(0)
const [dia, setDia] = useState();
const [news, setNews] = useState();




  useEffect(() => {
    console.log(dados);
    
    if (dados == undefined) return null


    if (dados.CBIO_EST == undefined) return null

    let volumeArr = dados.CBIO_VOL

  


    let revert = dados
    let s

  
   
    
    revert.CBIO.reverse()
  revert.CBIO_VOL.reverse()
  revert.CBIO_EST.reverse()

  
    setRevert(revert)

    console.log('recsas', revert)
    //  var meuArrayInvertido = dados.CBIO_VOL.slice(0).reverse();
    // let dadoss = dados
    // dadoss.recap = meuArrayInvertido


    // setDadosR(dadoss)


    let volume = []
    // let medio = []





    volumeArr.map(function (a, i) {



      volume.push({
        x: formatDate(a.dia), value: a.ult, "color": "black 0.5",
        "thickness": 1,
        "size": 1
      })
      //   medio.push({
      //     x: formatDate(a.data), value: a.preco, "color": "black 0.5",
      //     "thickness": 1,
      //     "size": 1
      //   })


    })


    // let data1 = [{
    //   data: medio,
    //   name: 'Preço Médio',
    //   "stroke": "2 #5b6f2c",
    // }]


    // let jsonChart0 = {
    //   "chart": {
    //     "type": "line",
    //     "series": data1,

    //     "stroke": "5 red",

    //     "legend": false,
    //     "title": {
    //       'enabled': false
    //     },
    //     // y axes settings
    //     yAxes: [{     // chart.yAxis().title().text("Basic Y Axis");
    //     }, {                                // chart.yAxis(1)
    //       orientation: "right",              //   .orientation("right")
    //       //   .scale(customYScale)
    //     }],

    //     "container": "container"

    //   }
    // }

    // setChartLine(jsonChart0)


    let dataBar = [{
      data: volume.slice(0).reverse(),
      name: 'Volume total negociado',
      "color": "#5b6f2c"

    }]

    let jsonChart2 = {
      "chart": {
        "type": "column",
        "series": dataBar,
        "legend": false,

        "size": 4,
        // y axes settings
        yAxes: [{     // chart.yAxis().title().text("Basic Y Axis");
        }, {                                // chart.yAxis(1)
          orientation: "right",              //   .orientation("right")
          //   .scale(customYScale)
        }]

      }
    }

    setChartBar(jsonChart2)
    setloading(false)


async function getCumprimento(){
  let response = await fetch(config.target);
  console.log(response.body);

  let resJson = await response.json();

  console.log('adsfas',resJson.ativos)


  console.log(resJson.ativos[1].dados.ult)
  setCumprimento((resJson.ativos[1].dados.ult / 1000000).toFixed(2))
  setDia(resJson.ativos[1].dados.dia)


  setMeta((resJson.ativos[0].dados.ult / 1000000).toFixed(2))

  // setCumprimento(resJson.ativos[1].dados.ult)




 let porcentagem = (resJson.ativos[1].dados.ult *100 )/resJson.ativos[0].dados.ult;

console.log((resJson.ativos[1].dados.ult * 100),resJson.ativos[1].dados.ult )
console.log(parseInt(resJson.ativos[0].dados.ult , 1000000), resJson.ativos[0].dados.ult)



Math.round10 = function(value, exp) {
  return decimalAdjust('round', value, exp);
};


console.log(porcentagem)
console.log(porcentagem.toFixed(2))
console.log(Math.round10(porcentagem.toFixed(2), -1).toFixed(1)); 

 setProgress(porcentagem.toFixed(1))
 

}


  

  getCumprimento()


//https://api.datagro.com/api/news/listmat?searchQuery=cbio&sort=1&quantity=20&page=0&lang=pt-br

  async function getNews(){
    let response = await fetch(`https://api.datagro.com/api/news/listmat?searchQuery=cbio&sort=1&quantity=20&page=0&lang=pt-br`);
    console.log(response.body);
    
   let resJson = await response.json();

   setNews(resJson);
   
}

getNews()



  }, [])



  // "overview","Overview","Visão geral"
  // "open","Open","Abertura"
  // "high","High","Max"
  // "low","Low","Min"
  // "day","day","dia"
  
  
  // "last","Last","Último"
  // "highFull","High","Máximo"
  // "lowFull","Low","Mínimo"
  // "var","Change","Variação"
  // "time","Time","Data"



  function t(texto) {

    let lann = 'pt'
    let langg
    if (lann == 'pt' || lann == 'pt-br') {
      langg = {
        lang: 'pt-br',
        title: 'CBIO - CRÉDITO DE DESCARBONIZAÇÃO',
        qtt: 'Qtd',
        last:"Último",
        highFull:"Máximo",
        lowFull:"Mínimo",
        var:"Variação",
        time:"Data",
        date: 'Data',
        vol: 'Volume',
        day:"dia",
        open:"Abertura",
        neg: 'Neg',
        medio: 'Médio',
        price: 'Preço Médio',
        maxi: 'Max',
        mini: 'Min',
        titleMedio: 'Trade Recap - Preço Médio',
        titleVolume: 'Trade Recap - Volume',
        titleOferta: 'Ofertas de Venda',
        titleTrade: 'Trade Recap',
        err: 'As credencias fornecidas não permitem acesso a esse conteúdo.'

      }
    } else if (lann == 'en' || lann == 'en-us') {
      langg = {
        lang: 'en',
        title: 'CBIO - CRÉDITO DE DESCARBONIZAÇÃO',
        qtt: 'Qtty',
        price: 'Ask',
        date: 'Date',
        vol: 'Volume',
        neg: 'Trades',

        overview:"Overview",
        open:"Open",
        high:"High",
        low:"Low",
        day:"day",
        
        
        last:"Last",
        highFull:"High",
        lowFull:"Low",
        var:"Change",
        time:"Time",


        medio: 'Average',
        titleMedio: 'Trade Recap - Average Price',
        titleVolume: 'Trade Recap - Volume',
        titleOferta: 'Order Book',
        titleTrade: 'Trade Recap',
        err: 'The credentials provided do not allow access to this content.'
      }
    }





    return langg[texto]

  }








  function Sidebar() {
    return (
      <EmptySidebar>
        <div className="meta">
          <div className="metaD">

            <div style={{ float: 'left', marginLeft: 10, marginTop: 24, fontSize: 17 }}>
              Meta {dia && formatY(dia)} ANP
              </div>
            <div style={{ float: 'right', marginRight: 10 }}>
              <h2 style={{ float: 'left', fontSize: 45 }}> {meta}</h2>
              <h3 style={{ float: 'right', marginTop: 23, marginLeft: 2, fontSize: 20 }}>MM</h3>
            </div>
          </div>

        </div>
        <div className="cumprimento">
          <div style={{ float: 'left', textAlign: "center", paddingLeft: 30, marginTop: 15 }}>

            <div className="cumprimentoTitle">Cumprimento</div>
            <div style={{ fontSize: 45, fontWeight: 'bold' }}>{cumprimento} <p style={{ fontSize: 20, fontWeight: 'bold', float: 'right', marginTop: 25, marginLeft: -10 }}>MM</p></div>
            <div style={{ fontSize: 13, marginLeft: 10, width: 150, textAlign: 'right' }} >em {dia && formatDate(dia)}</div>


          </div>




          <div style={{ float: 'right' }}>
            <ProgressBar
              radius={50}
              progress={progress}
              strokeWidth={5}
              strokeColor="#c5470f"
              strokeLinecap="square"
              trackStrokeWidth={8}
            >
              <div className="indicador">
                <div>{progress}%</div>
              </div>



            </ProgressBar>
          </div> </div>

        <div className="newsSidebar">
          <h4>Análise, Notícias e Dados </h4>
          <div className="newsList"
          >
            <ExtraNews data={news} />

            <a href={config.linkMoreNews} target="_blank" className="link">
              <div className="read" >VER TODAS</div>
            </a>

          </div>





        </div>


        {dados.banner &&
          <div>
            <a href={dados.banner.link} className="link">
              <img border="0" src={dados.banner.imagem} />
            </a>
          </div>

        }

      </EmptySidebar>)
  }


  return (
    <div>



      <div className="bodyCbio"  >
        <TopBar />
        <Clear />
        <div className="header">
          <div className="opac">
            <img src={config.logoLeft} width={350} />
            <img src={config.logoRight} width={320} style={{ float: 'right' }} />

          </div>
        </div>
        <div style={{ marginTop: 40 }}></div>
        <div className="content">
          <div >



            <div style={{ marginLeft: 20, flexDirection: "row" }} >



              <div style={{ width: '73%', float: 'left' }}>
                <Header t={t} api={config.apiQuote}/> </div>
          
              <div style={{ width: '21%', float: 'right' }}>
                <div className="carteiraSantander">
                <a href={config.miniBanner.link} target="_blank" className="link">
              <img src={config.miniBanner?.imagem} width="205px" />
            </a> 
                </div>
              </div>



              {/* <Header />  */}
              <div style={{ width: '73%', float: 'left' }}>

                <div className="titleCompra"><p className={styles.p}>ESTOQUE</p></div>
                <div style={{ maxHeight: 260, height: 260, overflow: 'auto' }}>
                  <table className="tableCbio" style={{ width: '100%' }}>
                    <caption></caption>
                    <tr className={styles.tr}>
                      <th className={styles.th}>{t('date')}</th>
                      <th className={styles.th}>Emissor</th>
                      <th className={styles.th}>Parte Obrigada</th>
                      <th className={styles.th}> Parte Não Obrigada</th>


                      <th className={styles.th}>Estoque</th>




                    </tr>
                    <tbody className={styles.tbody}>
                      {dadosR && dadosR.CBIO_EST.map(function (a, i) {
                        return (<tr className={styles.tr}>
                          <td className={styles.td}>{formatDate(a.dia)}</td>
                          <td style={{ textAlign: "right" }} className={styles.td}>{formatFloatLocale(a.emissor)}</td>
                          <td style={{ textAlign: "right" }} className={styles.td}>{formatFloatLocale(a.po)}</td>
                          <td style={{ textAlign: "right" }} className={styles.td}>{formatFloatLocale(a.pno)}</td>
                          <td style={{ textAlign: "right" }} className={styles.td}>{formatFloatLocale(a.ult)}</td>



                        </tr>)
                      })}
                    </tbody>
                  </table>
                </div>
              </div>




              <div style={{ width: '21%', float: 'right' }}>


                <div className="titleVenda"><p className={styles.p}>VOLUME</p></div>
                <div style={{ maxHeight: 260, height: 260, overflow: 'auto' }}>
                  <table className="tableCbio" style={{ width: '100%' }}>
                    <caption></caption>
                    <tr className={styles.tr}>
                      <th className={styles.th}>{t('date')}</th>
                      <th className={styles.th}>{t('vol')}</th>

                    </tr>
                    <tbody className={styles.tbody}>
                      {reverts && reverts.CBIO_VOL.map(function (a, i) {
                        return (<tr className={styles.tr}>
                          <td className={styles.td}>{formatDate(a.dia)}</td>
                          <td style={{ textAlign: "right", marginRight: 20 }} className={styles.td}>{formatFloatLocale(a.ult)}</td>
                        </tr>)
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>


            <Clear />



            <div style={{ flexDirection: 'column', width: '100%', float: 'none', marginTop: 10 }}>




              <div style={{ marginLeft: 20 }}>

                <div className="titleRecap"><p className={styles.p}>NEGÓCIOS </p></div>
                <div style={{ maxHeight: 300, overflow: 'auto', marginBottom: 15 }}>

                  <table className="tableCbio" style={{ width: '100%' }}>


                    <thead className={styles.thead} style={{ width: '100%' }}>
                      <tr className={styles.trh}>
                        <th className={styles.th} >{t('date')}</th>
                        <th className={styles.th} style={{ textAlign: "right" }}>{t('price')}</th>

                        <th className={styles.th} style={{ textAlign: "right" }} >{t('maxi')}</th>
                        <th className={styles.th} style={{ textAlign: "right" }}>{t('mini')}</th>
                        <th className={styles.th} style={{ textAlign: "right" }}>{t('neg')}</th>
                        <th className={styles.th} style={{ textAlign: "right" }}>{t('vol')}</th>


                      </tr>


                    </thead>
                    <tbody className={styles.tbody}>
                      {dadosR && dadosR.CBIO.map(function (a, i) {
                        return (<tr className={styles.tr}>
                          <td className={styles.td}>{formatDate(a.dia)}</td>
                          <td style={{ textAlign: "right" }} className={styles.td}>{formatFloat(a.ult, 2)}</td>


                          <td style={{ textAlign: "right" }} className={styles.td}>{formatFloat(a.maxi, 2)}</td>
                          <td style={{ textAlign: "right" }} className={styles.td}>{formatFloat(a.mini, 2)}</td>

                          <td style={{ textAlign: "right" }} className={styles.td}>{a.neg}</td>
                          <td style={{ textAlign: "right" }} className={styles.td}>{formatFloatLocale(a.vtt)}</td>


                        </tr>)
                      })}

                    </tbody>

                  </table>
                </div>
              </div>
            </div>
          </div>




          {/* <Candle /> */}


          {!Loading &&
            <div style={{ width: '100%', marginTop: 30 }}>


              {/* {chartLine && <div style={{}} >
            <p className="tilteChart">HISTÓRICO DE PRECOS</p>
            <ChartJson data={chartLine} xGrid={false} background={'#eaeaea'} height={250} />

          </div>} */}


              {chartBar && <div style={{ marginTop: 30, marginBottom: 20 }} >  <p className="tilteChart" >CBIO</p> <Chart data={dados.CBIO} height={600} background={'#eaeaea'} container={'char1'} xGrid={false} /></div>}



              {chartBar && <div style={{ marginTop: 30 }} >  <p className="tilteChart" >Volume</p> <ChartJson data={chartBar} height={250} background={'#eaeaea'} container={'char1'} xGrid={false} /></div>}

            </div>
          }

        </div>
        <Sidebar />
        <Clear />
        <div className="footerCbio">
          <img src="https://assets.datagro.com/logo.png" width={150} style={{ float: 'left',padding: 20,  marginBottom: -30 }} />
          <Clear />
          <p style={{ fontSize: 10, color: '#333', padding:20}}>{config && config.disclaimer}</p>
          {/* <img src="" width={200}  style={{float: 'right', paddingTop: 40, paddingRight: 20}}/> */}
        </div>
      </div>
    </div>
  )
}




const EmptySidebar = ({ className, children }) => {

  var mainClassName = "mainSidebar"

  return (
    <div className={mainClassName}>
      {children}
      <Clear />
    </div>
  )
}



export default AutoLoadQuote
export { AutoLoadQuote, Santander }


function decimalAdjust(type, value, exp) {
  // Se exp é indefinido ou zero...
  if (typeof exp === 'undefined' || +exp === 0) {
    return Math[type](value);
  }
  value = +value;
  exp = +exp;
  // Se o valor não é um número ou o exp não é inteiro...
  if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
    return NaN;
  }
  // Transformando para string
  value = value.toString().split('e');
  value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
  // Transformando de volta
  value = value.toString().split('e');
  return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
}